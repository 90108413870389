import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';
import { ArrowForward } from '@material-ui/icons';

import { styleConfig } from '../../config/config';

import Title from './Common/Title';
import FaqList from './FaqList';

const Faq: React.SFC<FaqProps> = ({ subtitle, headline, faqlist, hasButton }) => {
  const useStyles = makeStyles(() => ({
    root: {
      backgroundColor: styleConfig.color.bg.darkgrey,
    },
    button: {
      transition: 'all .3s ease-in-out',
      display: 'flex',
      margin: '2rem 0',
      color: styleConfig.color.text.white,
      textDecoration: 'none',
      justifyContent: 'flex-end',

      '& span': {
        display: 'inline-block',
        verticalAlign: 'middle',
        color: styleConfig.color.bg.gold,

        '& svg': {
          width: 20,
          height: 20,
        },
      },

      '&:hover': {
        color: styleConfig.color.bg.gold,
      },
    },
  }));

  const classes = useStyles();

  return (
    faqlist && (
      <Container maxWidth="xl" className={classes.root}>
        <Grid container justifyContent="center" direction="column">
          <Title
            component="h2"
            alignment="center"
            isSubtitle
            marginBottom={25}
            subtitleText={subtitle}
            subtitleType="subtitle1"
            titleCaps
            titleColor={styleConfig.color.text.white}
            titleHeavy
            titleSize="h2"
            titleText={headline}
          />

          <FaqList list={faqlist} dark />

          {hasButton && (
            <Link aria-label="Ask a question" to="/contact-us/" className={classes.button}>
              Ask a question <ArrowForward />
            </Link>
          )}
        </Grid>
      </Container>
    )
  );
};

interface FaqProps {
  subtitle: string;
  headline: string;
  faqlist: {
    title_question: string;
    content_answer: string;
  }[];
  hasButton?: boolean;
}

export default Faq;
