import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Config
import { styleConfig } from '../../config/config';

const Review: React.SFC<ReviewProps> = ({ reviewContent, reviewName, reviewSource }) => {
  const useStyles = makeStyles(() => ({
    root: {
      height: '100%',
    },
    review: {
      display: 'block',
      quotes: '"“" "”" "‘" "’"',
      textAlign: 'center',
      paddingTop: '6rem',
      position: 'relative',

      '&:before': {
        position: 'absolute',
        top: '0',
        left: '50%',
        transform: 'translate3d(-50%, 0, 0)',
        willChange: 'transform',
        content: 'open-quote',
        lineHeight: 1,
        fontSize: 80,
        fontWeight: 600,
        color: styleConfig.color.text.white,
      },
    },
    starDefault: {
      fontSize: 20,
      color: styleConfig.color.text.darkgrey,
      opacity: 0.2,
    },
    starSelected: {
      fontSize: 20,
      color: styleConfig.color.text.darkgrey,
      opacity: 1,
    },
    content: {
      display: 'block',
      fontFamily: 'Bai Jamjuree',
      fontSize: 20,
      fontWeight: 500,
      marginTop: 20,
      marginBottom: 50,
      color: styleConfig.color.text.white,
    },
    name: {
      display: 'block',
      marginBottom: '.5rem',
      fontSize: 14,
      fontWeight: 'bold',
      color: styleConfig.color.text.white,
    },
    source: {
      fontSize: 12,
      color: styleConfig.color.text.white,
      opacity: 0.6,
    },
  }));

  const classes = useStyles();

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.root}>
      <Grid item xl={8} lg={8} md={10} sm={12} xs={12} className={classes.review}>
        <Typography
          className={classes.content}
          component="div"
          key="review-content"
          variant="body1"
          dangerouslySetInnerHTML={{ __html: reviewContent }}
        />
        <div className={classes.name}>{reviewName}</div>
        {reviewSource && <div className={classes.source}>source: {reviewSource}</div>}
      </Grid>
    </Grid>
  );
};

interface ReviewProps {
  reviewRating?: number;
  reviewContent: string;
  reviewName: string;
  reviewSource?: string;
}

export default Review;
