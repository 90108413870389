import React from 'react';

import FaqListItem from './FaqListItem';

const FaqList: React.FunctionComponent<FaqListProps> = (props) => {
  const { dark, list } = props;

  if (list) {
    return (
      <React.Fragment>
        {list.map((faq, index) => (
          <div key={`faqList-${index}`}>
            <FaqListItem
              dark={dark}
              num={index}
              question={faq.title_question}
              answer={faq.content_answer}
            />
          </div>
        ))}
      </React.Fragment>
    );
  }

  return null;
};

interface FaqListProps {
  dark?: boolean;
  list?: {
    title_question: string;
    content_answer: string;
  }[];
}

export default FaqList;
