import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { styleConfig } from '../../config/config';

import Review from './Review';
import Faq from './Faq';

const ContentModuleReviewFaq: React.FunctionComponent<ContentModuleReviewFaqProps> = (props) => {
  const { review, faqs, hasButton, title, subtitle } = props;

  const useStyles = makeStyles(() => ({
    sectionReview: {
      padding: '100px 24px',
      background: styleConfig.color.bg.gold,
    },
    sectionFaq: {
      background: styleConfig.color.bg.darkgrey,
      padding: '100px 24px',
    },
  }));

  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} md={6} className={classes.sectionReview}>
        <Review
          reviewContent={review && review.post_content && review.post_content}
          reviewName={review && review.post_title && review.post_title}
          reviewRating={review && review.acf && review.acf.rating && review.acf.rating}
        />
      </Grid>

      <Grid item xs={12} md={6} className={classes.sectionFaq}>
        <Faq subtitle={subtitle} headline={title} hasButton={hasButton} faqlist={faqs} />
      </Grid>
    </Grid>
  );
};

interface ContentModuleReviewFaqProps {
  hasButton: boolean;
  title: string;
  subtitle: string;
  review: {
    acf: {
      rating: number;
    };
    post_content: string;
    post_title: string;
  };
  faqs: any[];
}

export default ContentModuleReviewFaq;
