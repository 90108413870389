import React, { useState } from 'react';
import { KeyboardArrowDownOutlined } from '@material-ui/icons';
import { Grid, Typography, Collapse, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { styleConfig } from '../../config/config';

const FaqList: React.SFC<FaqListProps> = (props) => {
  const { num, question, answer, dark } = props;

  const [isToggled, setIsToggled] = useState(false);

  const useStyles = makeStyles((theme: Theme) => ({
    answerRoot: {
      margin: '0px 0 30px 0',

      [theme.breakpoints.down('sm')]: {
        margin: '10px 0 30px 0',
      },
    },

    listitem: {
      display: 'block',
      position: 'relative',
      width: '100%',
      marginBottom: '1rem',
      borderBottom: '1px solid #353535',
      cursor: 'pointer',

      '&:hover $questionTitle': {
        color: styleConfig.color.text.gold,
      },
    },

    question: {
      display: 'block',
      width: '100%',
      background: 'none',
      border: 'none',
      outline: 'none',
      textAlign: 'left',
      cursor: 'pointer',
    },

    questionTitle: {
      transition: 'all .3s ease-in-out',
      fontFamily: 'Bai Jamjuree',
      fontSize: 20,
      fontWeight: 600,
      color: dark ? styleConfig.color.text.white : styleConfig.color.text.darkgrey,
      padding: '0 0 10px',

      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },

    icon: {
      transform: isToggled ? 'rotate(180deg)' : 'inherit',
      transformOrigin: 'center',
      transition: '0.3s ease-in-out transform',
      float: 'right',
      display: 'block',
      fontSize: 20,
      textAlign: 'right',
      color: styleConfig.color.text.gold,

      '& svg': {
        verticalAlign: 'top',
        transition: 'all .3s ease-in-out',
        transformOrigin: 'center center',
      },
    },

    questionNum: {
      color: styleConfig.color.text.gold,
      fontFamily: 'Bai Jamjuree',
      fontWeight: 700,
    },

    questionAnswer: {
      padding: '0 0.5rem',
      color: dark ? '#c0c0c0' : 'inherit',
      fontSize: 15.5,
    },
  }));

  const classes = useStyles();

  function handleToggle() {
    setIsToggled(isToggled === false);
  }

  return (
    <div className={classes.listitem}>
      <button
        aria-label={`Toggle - ${question} `}
        className={classes.question}
        onClick={handleToggle}
      >
        <Grid container>
          <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
            <span className={classes.questionNum}>
              {num > 8 ? '' : '0'}
              {num + 1}
            </span>{' '}
            {/* This is 8, because it starts at 0 */}
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
            <Typography component="p" className={classes.questionTitle}>
              {question}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            {/* You need to wrap icons like this to do any transformations. */}
            <div className={classes.icon}>
              <KeyboardArrowDownOutlined fontSize="large" />
            </div>
          </Grid>
        </Grid>
      </button>

      <Collapse in={isToggled} collapsedSize="0">
        <Grid container justifyContent="space-around">
          <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
            <Box className={classes.answerRoot}>
              <Typography
                component="div"
                dangerouslySetInnerHTML={{ __html: answer }}
                className={classes.questionAnswer}
              />
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </div>
  );
};

interface FaqListProps {
  dark?: boolean;
  num: number;
  question: string;
  answer: string;
}

export default FaqList;
